import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import styled from "styled-components";
import { Module } from "../components/Module";
import { ListAutoHide } from "../components/ListAutoHide";
import { Formik } from "formik";
import axios from "axios";
import { Link } from "gatsby";

const pageData = {
  header: "Projektowanie stron",
  mainTitle: "Ty decydujesz,",
  subtitle: "my projektujemy",
};

const OfferDevContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows:
    minmax(100px, max-content)
    repeat(auto-fill, 200px) 17%;
  grid-area: last-line;
  max-width: 1980px;
  gap: 40px;
  width: 80vw;
  margin: 0 auto;
  @media (max-width: 1299px) {
    grid-template-rows:
      minmax(100px, max-content)
      repeat(auto-fill, 200px) 40%;
    gap: 40px;
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    width: 90vw;
  }
`;

const modules = [
  {
    category: "Domena, hosting, projekt graficzny + ",
    title: "One-Page",
    text: (
      <p>
        <span>
          <b>Strona w formule "One-Page"</b> (pojedyńcza strona, przewijana w
          dół).
        </span>
        <br />
        <span>
          <b>Moduły:</b> menu, blog, galeria zdjęć, formularz e-mail.
        </span>
        <br />
        <span>
          <b>RWD</b> - widok dostosowany do komputerów stacjonarnych i urządzeń
          mobilnych.
        </span>
      </p>
    ),
    price: 1799,
    priceDemo: "1799",
  },
  {
    category: "Domena, hosting, projekt graficzny + ",
    title: "Multi-Page",
    text: (
      <p>
        <span>
          <b>Strona główna + 4 Podstrony</b> <br />
          (każda dodatkowa + 329 zł netto).
        </span>
        <br />
        <span>
          <b>Moduły:</b> menu, blog, galeria zdjęć, formularz e-mail.
        </span>
        <br />
        <span>
          <b>RWD</b> - widok dostosowany do komputerów stacjonarnych i urządzeń
          mobilnych.
        </span>
      </p>
    ),
    price: 1799,
    priceDemo: "1799",
  },
  {
    category: "Moduł CMS",
    title: "Formularz zapytań o ofertę",
    text: (
      <p>
        <span>
          Właśnie z niego korzystasz. Jeżeli chcesz mieć możliwość
          własnoręcznego dodawania ofert do specjalnego formularza, to
          koniecznie wybierz ten moduł.
        </span>
      </p>
    ),
    price: 649,
    priceDemo: "649",
  },
  {
    category: "Moduł CMS",
    title: "Kariera",
    text: (
      <p>
        <span>
          W Twojej firmie prowadzone są rekrutacje? Z pewnością chciałbyś, aby
          dział Kadr mógł samodzielnie dodawać nowe oferty, bez pomocy
          programisty. Jeżeli tak - ten moduł jest dla Ciebie!
        </span>
      </p>
    ),
    price: 649,
    priceDemo: "649",
  },

  {
    category: "Moduł CMS",
    title: "Galeria zdjęć",
    text: (
      <p>
        <span>
          Samodzielnie zmieniaj zdjęcia w galerii za pomocą prostego w użyciu
          <b> Content Management System (CMS).</b>
        </span>
      </p>
    ),
    price: 329,
    priceDemo: "329",
  },
  {
    category: "Moduł CMS",
    title: "Ruchomy baner",
    text: (
      <p>
        <span>
          Doskonały do prezentacji oferty, aktualności i czego tylko
          potrzebujesz - masz pełną kontrolę nad tym, co się w nim znajduje.
        </span>
      </p>
    ),
    price: 329,
    priceDemo: "329",
  },
  {
    category: "Moduł CMS",
    title: "Realizacje",
    text: (
      <p>
        <span>
          Pochwal się swoimi projektami w dedykowanym module zarządzanym z
          zewnętrznego źródła, jakim jest CMS.
        </span>
      </p>
    ),
    price: 329,
    priceDemo: "329",
  },
  {
    category: "Moduł CMS",
    title: "Referencje",
    text: (
      <p>
        <span>
          W łatwy sposób zamieszczaj na Stronie <b>opinie i referencje </b>od
          swoich Klientów czy Kontrahentów, zadowolonych z wykonanych usług.
        </span>
      </p>
    ),
    price: 329,
    priceDemo: "329",
  },
  {
    category: "Moduł CMS",
    title: "Partnerzy",
    text: (
      <p>
        <span>
          Środowisko biznesowe podlega ciągłym zmianom - z tym modułem możesz
          samodzielnie dodawać i usuwać <b>Partnerów biznesowych</b> ze swojej
          Strony.
        </span>
      </p>
    ),
    price: 329,
    priceDemo: "329",
  },

  {
    category: "Moduł CMS",
    title: "FAQ",
    text: (
      <p>
        <span>
          Warto rozwiewać wszystkie wątpliwości już na starcie. Jendak problemy,
          tak jak ludzie, się zmieniają. Jeżeli potrzebujesz mieć możliwość
          łatwej zmiany treści w panelu FAQ, to nie pomijaj tego modułu.
        </span>
      </p>
    ),
    price: 329,
    priceDemo: "329",
  },
];

const p1 = [
  {
    txt: (
      <p>
        <span>
          Wyrażam zgodę na kontakt w celach marketingowych i handlowych na numer
          telefonu podany przeze mnie w formularzu. Zgodę będę mogła/mógł
          wycofać w każdym momencie przez kontakt z opiekunem klienta lub
          Administratorem.
        </span>{" "}
        <br /> <br />{" "}
        <span>
          Administatorem danych osobowych jest JB Multimedia ul. Brzeska 107A
          21-500 Biała Podlaska. Dane wpisane w formularzu kontaktowym będą
          przetwarzane w celu udzielenia odpowiedzi na przesłane zapytanie
          zgodnie z <Link to="/regulamin">Regulaminem</Link>
        </span>
      </p>
    ),
  },
];

const OfferDevPage = () => {
  const [selectedModules, setSelectedModules] = useState([]);
  const [sumValue, setSumValue] = useState(0);
  const [selectedModulesTitle, setSelectedModulesTitle] = useState([]);
  const [active, setActive] = useState("active");
  const [scrolled, setScrolled] = useState("normal");

  const calculateTotalPrice = () => {
    const totalPrice = selectedModules.reduce((total, modulesIndex) => {
      const modulePrice = modules[modulesIndex].price;
      return total + modulePrice;
    }, 0);
    setSumValue(totalPrice);
  };

  const changeIndexesToTitles = () => {
    const titlesArray = selectedModules.map((modulesIndex) => {
      return modules[modulesIndex].title;
    }, 0);
    setSelectedModulesTitle(titlesArray.join(", "));
  };

  const updateSelectedModules = (module, isSelected) => {
    if (isSelected) {
      setSelectedModules([...selectedModules, module]);
    } else {
      const updatedModules = selectedModules.filter(
        (selectedModule) => selectedModule !== module
      );
      setSelectedModules(updatedModules);
    }
  };

  useEffect(() => {
    calculateTotalPrice();
    changeIndexesToTitles();
    window.addEventListener("load", function () {
      return setActive("active");
    });

    const handleScroll = () => {
      if (window.scrollY >= 1075) {
        setScrolled("active");
      } else {
        setScrolled("normal");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [selectedModules]);

  return (
    <>
      <div className={`offer-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="offer-dev-page">
          <div className="advantages txt">
            <div className="txt-container">
              <h4>Zaprezentuj się On-line</h4>
              <h2>
                Pomożemy Ci zbudować Stronę,
                <br /> <strong>jakiej pozazdrości Ci świat</strong>
              </h2>
            </div>
            <p className="headerTxt">
              Nasze Strony powstają w nowoczesnym środowisku{" "}
              <strong>Jam Stack</strong> (JavaScript-APIs-Markup).
              Charakteryzują się tym, że system CMS (system do zarządzania
              treścią - używamy DATO CMS - konfigurowalny i bajecznie prosty w
              obsłudze) wykorzystywany jest jedynie jako zaplecze do edycji
              treści, natomiast warstwa, która wyświetla się użytkownikom to
              tzw. <b>"strona statyczna"</b>. Dla przeglądarki jest to prosty
              kod, dzięki czemu Strona <strong>działa bardzo szybko</strong>{" "}
              oraz <strong>jest zupełnie bezpieczna</strong> - w przeciwieństwie
              do Stron internetowych mających stałe połączenie z CMS (jak np.
              Wordpress) - ponieważ nie łączy się w czasie rzewczywistym z
              żadnym serwerem i jest dostarczana w całości do przeglądarki
              odwiedzającego.
            </p>
            <p className="headerTxt">
              Z nami dokonasz wstępnej wyceny decydując się na któryś z
              <strong> Podstawowych Modułów (One-Page vs Multi-Page)</strong> i
              rozbudowując go według swoich potrzeb, np. o{" "}
              <strong>Moduły CMS</strong>, lub inne, które sam zaproponujesz.
              Następnie w prosty sposób wyślesz zapytanie, używając dedykowanego
              formularza. Gdy już się zdecydujesz, które moduły będą Ci
              potrzebne, po prostu naciśnij przycisk{" "}
              <strong>"Wyślij zapytanie"</strong>, a Twoja wiadomość dotrze do
              nas wraz z niezbędnymi informacjami - możesz tam również dodać
              swój komentarz. Dalszy ruch należy do nas -
              <b> odezwiemy się do Ciebie</b>, uzgodnimy szczegóły, przedstawimy
              ostateczną ofertę{" "}
              <strong>i rozpoczniemy pracę nad Twoją nową Stroną.</strong>
            </p>
          </div>
          <OfferDevContainer className="offer-dev-container">
            <div className="offer-dev-container-wrapper">
              {modules.map((module, index) => (
                <div className="offer-element">
                  <Module
                    key={index}
                    category={modules[index].category}
                    title={modules[index].title}
                    text={modules[index].text}
                    price={modules[index].price}
                    priceDemo={modules[index].priceDemo}
                    index={index} // Pass the index of the module
                    isSelected={selectedModules.includes(index)} // Check if this module is selected
                    updateSelectedModules={updateSelectedModules} // Pass the updateSelectedModules function
                  ></Module>
                </div>
              ))}
            </div>
            <div className={`offer-resume ${scrolled}`}>
              <div className="resume-wrapper">
                <div>
                  {" "}
                  <p>
                    <b>Wybrane moduły:</b>
                  </p>
                  {selectedModulesTitle ? (
                    <p>{selectedModulesTitle}</p>
                  ) : (
                    <p>Jeszcze nic nie wybrano</p>
                  )}
                </div>
                <h2>Suma: {sumValue} zł (netto)</h2>
                <p className="action-txt">
                  Wyślij zapytanie ofertowe, a my odezwiemy się do Ciebie:
                </p>
                <div className="contactWrapper">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      sum: sumValue,
                      selectedModule: selectedModulesTitle,
                      name: "",
                      phone: "",
                      email: "",
                      message: "",
                      check: false,
                    }}
                    validate={(values) => {
                      let errors = {};
                      if (!values.email) {
                        errors.email = <p>Podaj swój e-mail:</p>;
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = <p>Niepoprawny adres e-mail:</p>;
                      }
                      if (!values.name) {
                        errors.name = <p>Podaj swoje imię:</p>;
                      } else if (typeof values.name != "string") {
                        errors.name = <p>Imię musi być tekstem:</p>;
                      } else if (values.name.length < 3) {
                        errors.name = <p>Imię musi zawierać min. 3 znaki:</p>;
                      }
                      if (!values.phone) {
                        errors.phone = <p>Podaj nr telefonu kom.</p>;
                      }
                      if (!values.message) {
                        errors.message = <p>Uzupełnij treść wiadomości:</p>;
                      }
                      if (!values.check) {
                        errors.check = <p>Pole wymagane:</p>;
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      axios
                        .post(
                          "https://us-central1-jbmultimedia-gatsbyjs-project.cloudfunctions.net/sendEmail",
                          values
                        )
                        .then((res) => {
                          console.log(res);
                          setSubmitting(false);
                        })
                        .catch((err) => {
                          console.log(err);
                          setSubmitting(false);
                        });
                      resetForm({ values: "" });
                      setTimeout(() => {
                        alert("Twoja wiadomość została wysłana");
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="form"
                        name="contact-form"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                      >
                        <span></span>
                        {errors.name && touched.name && errors.name}
                        <input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Imię i nazwisko"
                          autocomplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.phone && touched.phone && errors.phone}
                        <input
                          id="phone"
                          name="phone"
                          type="tel"
                          pattern="[0-9]{9}"
                          autocomplete="off"
                          placeholder="Telefon kom."
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                        {errors.email && touched.email && errors.email}
                        <input
                          name="email"
                          type="email"
                          placeholder="Email"
                          autocomplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                        {errors.message && touched.message && errors.message}
                        <textarea
                          name="message"
                          type="text"
                          id="message"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.message}
                          placeholder="Treść wiadomości"
                        />
                        {errors.check && touched.check && errors.check}
                        <div className="checkBoxWrapper">
                          <label>
                            <input
                              className="check"
                              name="check"
                              type="checkbox"
                              onChange={handleChange}
                              checked={values.check}
                            ></input>

                            <ListAutoHide
                              header="Zapoznałem się z informacją o administratorze i przetwarzaniu danych"
                              paragraph={p1[0].txt}
                            />
                          </label>
                          <br />
                          <p></p>
                        </div>
                        <button
                          className="btn-red"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Wyślij zapytanie
                        </button>
                        {/* <img
                      className="imgContact"
                      src={require("../images/Contact/soldier.webp").default}
                      alt=""
                    /> */}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </OfferDevContainer>
        </section>
      </Layout>
    </>
  );
};
export const Head = () => (
  <Seo title="Oferta projektowania stron internetowych" />
);
export default OfferDevPage;
