import React, { useState } from "react";

export function Module(props) {
  const {
    category,
    title,
    text,
    price,
    priceDemo,
    index,
    index2,
    isSelected,
    updateSelectedModules,
  } = props;
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = () => {
    // Toggle isSelected based on the current state
    setActiveIndex(index2 === activeIndex ? null : index2);
    updateSelectedModules(index, !isSelected);
  };

  return (
    <>
      <div className={activeIndex === index2 ? "order" : ""}>
        <div className="txt-container">
          <h4>{category}</h4>
          <h2>{title}</h2>
          <div>{text}</div>
          <div className="price">
            <div>
              <span>już od:</span>{" "}
              <span>{isSelected ? price : priceDemo} zł</span>{" "}
              <span>netto</span>
            </div>

            <div>
              <button className="btn-red" onClick={() => handleClick(index)}>
                {isSelected ? "Wybrano" : "Wybieram"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
